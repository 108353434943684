import { Controller } from "@hotwired/stimulus"
import * as Bootstrap from "bootstrap";

// Connects to data-controller="popper"
export default class extends Controller {
  static targets = ["tooltip"];

  tooltipTargetConnected(target) {
    this.tooltips ||= {};
    this.tooltips[target] = new Bootstrap.Popover(target, { html: true, sanitize: false });
  }

  tooltipTargetDisconnected(target) {
    if (this.tooltips[target]) {
      this.tooltips[target].dispose();
      delete this.tooltips[target];
    }
  }
}