window.updateCallStatus = () => {
  const refresh = () => {
    Axios.get(`${window.location.href}/call_status`.replace('//call_status', '/call_status'))
      .then((response) => {
        if (response.data.status === 'completed') {
          window.location.reload();
        } else {
          const statusElement = document.getElementById('status');
          statusElement.innerHTML = response.data.html;
        }
      })
  }

  setInterval(refresh, 1500);
}
