window.videojs = require("./videojs/video.js");
require("./videojs/nuevo.min.js");

document.addEventListener("DOMContentLoaded", function () {
  let currentVideo = null; // Variable to track the currently playing video

  /**
   * Custom plugin to make pausing on button click possible and not just the background.
   * Kind of sad the button doesn't work by default lol.
   * We disabled background click for play/pause, hence why this is necessary.
   */

  videojs.registerPlugin("kawabPlugin", function (pluginOptions) {
    pluginOptions = pluginOptions || {};

    const player = this;
    const playPauseButton = player.el().querySelector(".vjs-big-play-button");

    const isPlaying = () => player.el().classList.contains("vjs-playing");

    const showPlayButton = () =>
      setTimeout(() => playPauseButton.classList.remove("vjs-abs-hidden"), 100);

    const handlePlayPauseClick = (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (!isPlaying()) {
        return;
      }

      player.pause();

      showPlayButton();
    };

    playPauseButton.addEventListener("click", handlePlayPauseClick);
    playPauseButton.addEventListener("touchend", handlePlayPauseClick);

    player.on("play", function () {
      // Pause the previous video if a new video starts playing
      if (currentVideo && currentVideo !== player) {
        currentVideo.pause();
      }
      currentVideo = player; // Update the current video to the newly started video
    });

    player.on("pause", function () {
      // Clear the current video if it is paused
      showPlayButton();

      if (currentVideo === player) {
        currentVideo = null;
      }
    });
  });

  document.querySelectorAll(".video-js").forEach((playerE) => {
    const player = videojs(playerE, { controls: true });
    player.nuevo();
    player.kawabPlugin();

    if (!(playerE.querySelector("source") || {}).src) {
      playerE.parentElement.classList.add("no-source");
    }
  });
});
