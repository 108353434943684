import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from 'env';
import Swal from 'sweetalert2';

window.setupStripe = async (form, cardElement, paymentButton, errorElement, createIntentPath) => {
  paymentButton.disabled = true;

  const {data} = await Axios.post(createIntentPath);

  const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
  const elements = stripe.elements();
  const card = elements.create("card", {
    hidePostalCode: false,
    style: {
      base: {
        fontSize: "16px",
      },
      invalid: {}
    }
  });

  card.mount(cardElement);
  card.on("change", function (event) {
    // Disable the Pay button if there are no card details in the Element
    paymentButton.disabled = event.empty;
    document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
  });

  form.addEventListener("submit", function (event) {
    event.preventDefault();

    onStripeFormSubmit(stripe, card, data.client_secret, form);
  });

  form.querySelector('input[type="submit"]').addEventListener('click', (e) => {
    const country = document.querySelector('[name="country"]');
    if (country && !country.value) {
      e.preventDefault();
      e.stopPropagation();
      Swal.fire({
        title: "Oops",
        html:  "Le pays est obligatoire.<br>.<br>Country is mandatory",
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    }
  });

  const onStripeFormSubmit = function (stripe, card, clientSecret, form) {
    paymentButton.disabled = true;
    errorElement.textContent = '';

    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: card,
          billing_details: {
            email: form.email.value,
            name: form.name.value,
            address: {
              city: form.city.value,
              country: form.country.value
            }
          }
        },
      })
      .then(function (result) {
        if (result.error) {
          errorElement.textContent = result.error.message;
          paymentButton.disabled = false;
        } else {
          window.location = data.success_url
        }
      });
  }
}