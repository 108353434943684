import {Controller} from "@hotwired/stimulus"
import insertText from "insert-text-at-cursor";
import emojiRegex from "emoji-regex";

// Connects to data-controller="sms-text-area"
export default class extends Controller {
  static targets = ["textarea"];

  connect() {
    this.smsUnitPrice = parseFloat(this.element.dataset.unitPrice);
    this.initializeComponents();
  }

  initializeComponents() {
    this.statusBar = document.createElement("div");
    this.statusBar.classList.add("status-bar");

    this.emojiPicker = document.createElement("emoji-picker");
    this.emojiPicker.classList.add("pe-auto");
    this.statusBar.appendChild(this.emojiPicker);

    this.emojiButton = document.createElement("i");
    this.emojiButton.classList.add("fas", "fa-smile");
    this.emojiButton.classList.add("pe-auto");
    this.statusBar.appendChild(this.emojiButton);

    this.counter = document.createElement("span");
    this.counter.classList.add("counter");
    this.counter.textContent = this.getStatusBarText(0);

    this.moneyIcon = document.createElement("i");
    this.moneyIcon.classList.add("fas", "fa-coins", "ms-1", "fs-8");

    if (this.element.dataset.tokensText) {
      this.moneyIcon.dataset.popperTarget = "tooltip";
      this.moneyIcon.dataset.bsContent = this.element.dataset.tokensText;
      this.moneyIcon.dataset.bsTrigger = "hover focus";
      this.moneyIcon.dataset.bsPlacement = "top";
      this.moneyIcon.classList.add("pe-auto");
    }


    this.counterContainer = document.createElement("div");
    this.counterContainer.appendChild(this.counter);
    this.counterContainer.appendChild(this.moneyIcon);


    this.statusBar.appendChild(this.counterContainer);

    this.element.appendChild(this.statusBar);

    this.initializeEmojiPicker();
    this.initializeStatusBarText();
  }

  initializeEmojiPicker() {
    this.emojiButton.addEventListener('click', () => this.emojiPicker.classList.toggle("d-block"));
    this.emojiPicker.addEventListener('emoji-click', (e) => {
      insertText(this.textareaTarget, e.detail.unicode);
      this.emojiPicker.classList.toggle("d-block");
    });
  }

  initializeStatusBarText() {
    this.textareaTarget.addEventListener('input', () => {
      this.counter.textContent = this.getStatusBarText(this.getCharactersCount());
    });
  }

  getStatusBarText(charCount) {
    return (this.element.dataset.statusText || "")
      .replace("{{ count }}", this.getCharactersLeft(charCount))
      .replace("{{ price }}", this.getPrice(charCount));
  }

  getPrice(charCount) {
    let unitCount = parseInt(charCount / this.getUnitLength()) + 1;
    if (charCount % this.getUnitLength() === 0) unitCount -= 1;
    return Math.max((unitCount * this.smsUnitPrice), this.smsUnitPrice).toFixed(2);
  }

  getCharactersLeft(charCount) {
    if (charCount > 0 && charCount % this.getUnitLength() === 0) {
      return 0;
    } else {
      return this.getUnitLength() - (charCount % this.getUnitLength());
    }
  }

  getCharactersCount() {
    /**
     * Javascript's length can be up to 8 for a single emoji. Better use the regex to count without.
     */
    return this.textareaTarget.value.replaceAll(emojiRegex(), "").length + this.getEmojisCount();
  }

  getEmojisCount() {
    return (this.textareaTarget.value.match(emojiRegex()) || []).length;
  }

  getUnitLength() {
    /**
     * Calculation based on https://support.plivo.com/hc/en-us/articles/360041298072-What-is-the-character-limit-for-SMS-text-messages-
     * It is considered Unicode as soon as there is an emoji.
     * Max length is greater if messages are not split into multiple sms.
     */
    if (this.getEmojisCount() > 0) {
      if (this.getCharactersCount() > 70) {
        return 67;
      } else {
        return 70;
      }
    } else {
      if (this.getCharactersCount() > 160) {
        return 153;
      } else {
        return 160;
      }
    }
  }
}
