import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="sms-conversation"
export default class extends Controller {
  connect() {
    ["click", "focus"].forEach((event) => {
      this.element.addEventListener(event, () => this.markAsRead());
    });

    // Opening / Page load on conversation
    this.markAsRead();
  }

  markAsRead() {
    if (this.element.dataset.markAsReadPath) {
      Axios.put(this.element.dataset.markAsReadPath).then((response) => {
        Turbo.renderStreamMessage(response.data)
      })
    }
  }
}
