import {Controller} from "@hotwired/stimulus"
import {loadTomSelect} from "../admin/libs/tom-select";

// Connects to data-controller="tomselect"
export default class extends Controller {
  static targets = ["ts"]

  tsTargetConnected(selectInput) {
    if (selectInput.classList.contains('tomselected')) return;
    if (selectInput.classList.contains('no-ts')) return;

    if (selectInput.classList.contains('tomselect-ajax')) {
      loadTomSelect(selectInput, true);
    } else {
      loadTomSelect(selectInput, false);
    }
  }
}
