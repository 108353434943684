import {loadScript} from "@paypal/paypal-js";

window.setupPaypal = async (paypal_client_id, currency, create_order_path, capture_order_path) => {
  let paypal;

  try {
    paypal = await loadScript({
      "clientId": paypal_client_id,
      "currency": currency,
      "disableFunding": "card,credit"
    });
  } catch (error) {
    console.error("failed to load the PayPal JS SDK script", error);
  }

  if (paypal) {
    try {
      await paypal.Buttons({
        createOrder: async () => {
          const response = await fetch(create_order_path, { method: 'POST' });

          const responseData = await response.json();
          return responseData.token;
        },
        onApprove: async (data) => {
          const response = await fetch(capture_order_path, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({token: data.orderID})
          });

          if (response.ok) {
            const responseData = await response.json();
            window.location = responseData.success_url;
          }
        }
      }).render("#paypal-button-container");
    } catch (error) {
      console.error("failed to render the PayPal Buttons", error);
    }
  }
}
