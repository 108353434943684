import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sms-conversation-list"
export default class extends Controller {
  static targets = ["items"];

  connect() {
    this.updateSelectedConversation();
  }

  updateSelectedConversation() {
    const selectedConversationId = (new URLSearchParams(location.search)).get('conversation_id');
    if (selectedConversationId) {
      this.itemsTargets.forEach((target) => {
        if (target.dataset.smsConversationId === selectedConversationId) {
          target.classList.add("active");
        }
      });
    }
  }
}
