const {Turbo} = require("@hotwired/turbo-rails");

// Incompatible with VideoJS
Turbo.session.drive = false;

require("@rails/ujs").start();
require("@rails/activestorage").start();

require('@popperjs/core');
require('bootstrap');

require('./controllers');

require('./website/lib/axios');
require('./website/lib/stripe');
require('./website/lib/paypal');
require('./website/lib/videojs/videojs');

require('./website/components/caller_id');

require('./website/pages/call_log');
require('./website/pages/sms');

require('emoji-picker-element');
