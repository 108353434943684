document.addEventListener("DOMContentLoaded", function(event) {
  const selects = document.getElementsByClassName('caller-id-selector');

  if (!selects.length) return;

  for (const select of selects) {
    select.addEventListener('change', (event) => {
      const customCallerIdContainer = event.target.parentNode.parentNode.querySelector('.input-group');

      customCallerIdContainer.querySelectorAll('select,input').forEach((child) => {
        if (event.target.value) {
          child.classList.add('d-none');
          child.parentNode.classList.add('d-none');
          child.setAttribute('disabled', 'disabled');
        } else {
          child.classList.remove('d-none');
          child.parentNode.classList.remove('d-none');
          child.removeAttribute('disabled');
        }
      })
    });
    select.dispatchEvent(new Event('change'));
  }
});